import * as Types from '../../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type FunnelContextProviderOrderQueryVariables = Types.Exact<{
  isNewOrder: Types.Scalars['Boolean'];
}>;


export type FunnelContextProviderOrderQuery = { __typename?: 'query_root', orders: Array<{ __typename?: 'orders', id: number, parentOrderId?: Types.Maybe<number>, contracts: Array<{ __typename?: 'customer_contracts', id: number, signatureFinishedAt?: Types.Maybe<any> }> }> };


export const FunnelContextProviderOrderDocument = gql`
    query funnelContextProviderOrder($isNewOrder: Boolean!) {
  orders(
    order_by: {id: desc}
    limit: 1
    where: {current_state: {name: {_in: ["draft", "created", "validated"]}}, parent_order_id: {_is_null: $isNewOrder}}
  ) {
    id
    parentOrderId: parent_order_id
    contracts(order_by: {id: desc}, limit: 1) {
      id
      signatureFinishedAt: signature_finished_at
    }
  }
}
    `;
export function useFunnelContextProviderOrderQuery(baseOptions: Apollo.QueryHookOptions<FunnelContextProviderOrderQuery, FunnelContextProviderOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FunnelContextProviderOrderQuery, FunnelContextProviderOrderQueryVariables>(FunnelContextProviderOrderDocument, options);
      }
export function useFunnelContextProviderOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FunnelContextProviderOrderQuery, FunnelContextProviderOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FunnelContextProviderOrderQuery, FunnelContextProviderOrderQueryVariables>(FunnelContextProviderOrderDocument, options);
        }
export type FunnelContextProviderOrderQueryHookResult = ReturnType<typeof useFunnelContextProviderOrderQuery>;
export type FunnelContextProviderOrderLazyQueryHookResult = ReturnType<typeof useFunnelContextProviderOrderLazyQuery>;
export type FunnelContextProviderOrderQueryResult = Apollo.QueryResult<FunnelContextProviderOrderQuery, FunnelContextProviderOrderQueryVariables>;