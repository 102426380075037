import cn from "classnames";

export const CatalogGrid = (props: {
  children: React.ReactNode;
  layoutHasFilters?: boolean;
  truncateOnTablet?: boolean;
}) => (
  <section className={cn(
      "w-full grid grid-cols-1 xs:grid-cols-2 gap-3 md:!grid-cols-3",
      props.layoutHasFilters || 'lg:!grid-cols-4',
      props.truncateOnTablet && "md:[&>:last-child]:hidden lg:[&>:last-child]:block"
    )}>
    {props.children}
  </section>
);
