import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { useFunnelContextProviderOrderQuery } from "./funnel-context-provider-order.query.generated";

type FunnelContextValue = {
  isSwap: boolean;
  orderId?: number;
  parentOrderId?: number;
  loading: boolean;
  isContractSigned: boolean;
  setIsContractSignedOnFrontend: Dispatch<SetStateAction<boolean>>;
  hasValidateKycInformation: boolean;
  setHasValidateKycInformation: Dispatch<SetStateAction<boolean>>;
};

const FunnelContext = createContext<FunnelContextValue>({
  isSwap: false,
  orderId: undefined,
  parentOrderId: undefined,
  loading: false,
  isContractSigned: false,
  setIsContractSignedOnFrontend: () => {},
  setHasValidateKycInformation: () => {},
  hasValidateKycInformation: false,
});

type FunnelContextProviderProps = {
  isSwap?: boolean;
};

export const FunnelContextProvider = ({
  isSwap = false,
  children,
}: PropsWithChildren<FunnelContextProviderProps>) => {
  const { data: funnelOrderData, loading } = useFunnelContextProviderOrderQuery(
    {
      variables: {
        isNewOrder: !isSwap,
      },
      fetchPolicy: "network-only",
    },
  );
  const [isContractSignedOnFrontend, setIsContractSignedOnFrontend] =
    useState(false);
  const [hasValidateKycInformation, setHasValidateKycInformation] =
    useState(false);

  const isContractSigned =
    isContractSignedOnFrontend ||
    !!funnelOrderData?.orders?.[0]?.contracts?.[0]?.signatureFinishedAt;

  const orderId = funnelOrderData?.orders?.[0]?.id;
  const parentOrderId =
    funnelOrderData?.orders?.[0]?.parentOrderId ?? undefined;

  return (
    <FunnelContext.Provider
      value={{
        isSwap,
        orderId,
        parentOrderId,
        loading,
        isContractSigned,
        setIsContractSignedOnFrontend,
        hasValidateKycInformation,
        setHasValidateKycInformation,
      }}
    >
      {children}
    </FunnelContext.Provider>
  );
};

export const useFunnelContext = () => {
  const funnelContext = useContext(FunnelContext);

  if (funnelContext === undefined) {
    throw new Error("useContext must be used with a ContextProvider");
  }

  return funnelContext;
};

export const useUnsafeFunnelContext = () => {
  const { orderId, ...context } = useFunnelContext();

  if (!orderId) {
    throw new Error(
      `useUnsafeFunnelContext used with undefined orderId - context: ${JSON.stringify(
        context,
      )}`,
    );
  }

  return { orderId, ...context };
};
