import cx from "classnames";
import Image from "next/image";
import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

type CatalogProductCardProps = {
  type?: "available" | "coming-soon" | "legacy";
  tag?: string;
  isOutOfStock?: boolean;
  href: string;
  children?: React.ReactNode;
};

export const CatalogProductCard = ({
  type,
  tag,
  isOutOfStock,
  href,
  children,
}: CatalogProductCardProps) => {
  const i18n = useI18n();

  const colorClass = {
    available: "from-white",
    "coming-soon": "from-cornflower-blue/25",
    legacy: "from-black/25",
  }[type || "available"];

  const tabClasses =
    tag &&
    {
      "custom-new": "bg-black text-white",
      "custom-out-of-stock": "bg-black/30 text-white ",
      "custom-coming-soon": "bg-black/30 text-white",
      "custom-black-friday": "bg-blackfriday text-black",
      "custom-free-upfront": "bg-freeupfront text-white",
      default: "bg-cornflower-blue text-white",
    }[tag.startsWith("custom-") ? tag : "default"];

  const tabLabel =
    tag &&
    {
      "custom-new": i18n.t("catalog.tags.new"),
      "custom-out-of-stock": i18n.t("catalog.tags.out-of-stock"),
      "custom-coming-soon": i18n.t("catalog.tags.coming-soon"),
      "custom-black-friday": i18n.t("catalog.tags.black-friday"),
      "custom-free-upfront": i18n.t("catalog.tags.free-upfront"),
      default: tag,
    }[tag.startsWith("custom-") ? tag : "default"];

  return (
    <Link href={href}>
      <a>
        <div
          className={cx(
            "overflow-hidden relative py-7 xs:pt-10 xs:pb-5 rounded-md border border-black/20 hover:border-black cursor-pointer",
            "bg-gradient-to-r xs:bg-gradient-to-t to-white",
            colorClass,
          )}
        >
          <div
            className={cx(
              "flex xs:flex-col gap-5 items-center px-3 xs:px-0",
              type === "coming-soon" || type === "legacy"
                ? "flex-row-reverse"
                : "flex-row",
              isOutOfStock ? "opacity-40" : "",
            )}
          >
            {children}
          </div>

          {/* Out of stock */}
          {isOutOfStock && (
            <div className="absolute top-16 left-12 sm:translate-x-1/2 xs:top-20 xs:left-1/2">
              <Image
                src="/images/catalog/icons/out-of-stock.svg"
                width={68}
                height={68}
                alt="Out of stock"
              />
            </div>
          )}

          {/* Tag */}
          <div
            className={cx(
              "absolute top-0 left-0 py-1 px-3 text-xs font-semibold uppercase rounded-br-md",
              tabClasses,
            )}
          >
            {tabLabel}
          </div>
        </div>
      </a>
    </Link>
  );
};
