import cx from "classnames";
import { Maybe } from "graphql";

import { useFunnelContext } from "shared/Funnel/FunnelContextProvider";
import { useSettings } from "shared/SettingsProvider/useSettings";
import { Dot } from "ui/Dot";
import { StrapiImage } from "ui/StrapiImage";
import { formatToCurrency } from "utils/price.utils";
import { usePriceAdvantage } from "utils/usePriceAdvantage";
import { useI18n } from "utils/with-i18n.utils";

import { CatalogProductCard } from "../CatalogProductCard";

type CatalogProductCardAvailableProps = {
  brand: string;
  slug: string;
  modelName: string;
  colorCodes: string[];
  image: {
    alternativeText: Maybe<string>;
    name?: string;
    medium?: string;
    thumbnail?: string;
  };
  subscriptionPrice: number;
  originalSubscriptionPrice?: Maybe<number>;
  upfrontPrice: number;
  originalUpfrontPrice: number | null;
  isNew?: boolean;
  isOutOfStock?: boolean;
  tagSlugs?: (string | undefined)[];
};

export const CatalogProductCardAvailable = ({
  brand,
  slug,
  modelName,
  colorCodes,
  image,
  isNew,
  isOutOfStock,
  subscriptionPrice,
  originalSubscriptionPrice,
  upfrontPrice,
  originalUpfrontPrice,
  tagSlugs,
}: CatalogProductCardAvailableProps) => {
  const i18n = useI18n();
  const { isBlackFriday } = useSettings();
  const { isSwap } = useFunnelContext();

  const isBlackWeekProduct = tagSlugs?.includes("promos") ?? false;
  const isBlackFridayTheme = isBlackFriday && isBlackWeekProduct;

  const {
    hasSeniorityDiscount,
    smartphoneFamilyHasSubscriptionPriceDrop,
    hasUpfrontPriceDrop,
  } = usePriceAdvantage({
    originalUpfrontPrice,
    upfrontPrice,
    originalSubscriptionPrice,
    subscriptionPrice,
  });

  let tag = undefined;
  if (isOutOfStock) {
    tag = "custom-out-of-stock";
  } else if (upfrontPrice == 0 || hasSeniorityDiscount) {
    tag = "custom-free-upfront";
  } else if (isBlackFridayTheme) {
    tag = "custom-black-friday";
  } else if (smartphoneFamilyHasSubscriptionPriceDrop || hasUpfrontPriceDrop) {
    tag = i18n.t('catalog.tags.price-drop');
  } else if (isNew) {
    tag = "custom-new";
  } 

  return (
    <CatalogProductCard tag={tag} isOutOfStock={isOutOfStock} href={`${isSwap ? '/swap' : ''}/smartphone${slug}`}>
      <div className="flex flex-row-reverse gap-3 items-center xs:flex-col">
        {/* Image */}
        <StrapiImage
          image={{
            alternativeText: image.alternativeText,
            medium: { url: image.medium, name: image.name },
            thumbnail: { url: image.thumbnail, name: image.name },
          }}
          size="medium"
          sizes="40vw"
          className="w-[92px] h-[120px] sm:w-[165px] sm:h-[165px]"
        />

        {/* Colors */}
        <div className="flex flex-col gap-2 w-max xs:flex-row">
          {colorCodes.map((color) => (
            <Dot key={color} dotColor={color} />
          ))}
        </div>
      </div>

      <div className="flex flex-col flex-1 gap-2 w-full max-w-[240px] xs:px-5">
        {/* Brand and model */}
        <div>
          <p className="text-sm sm:text-base">{brand}</p>
          <p className="text-lg font-semibold tracking-tighter sm:text-2xl">{modelName}</p>
        </div>

        {/* Subscription price */}
        <div>
          <p className="text-sm text-black/50 sm:text-base">
            <span>{i18n.t('catalog.label.subscriptionFrom')}</span>
            {smartphoneFamilyHasSubscriptionPriceDrop && (
              <span className="ml-1 line-through">
                {formatToCurrency("fr-FR", "EUR", originalSubscriptionPrice ?? 0, undefined, false)}
              </span>
            )}
          </p>
          <p>
            <span className={cx('text-2xl font-semibold sm:text-3xl tracking-tight', smartphoneFamilyHasSubscriptionPriceDrop ? "text-cornflower-blue" : '')}>
              {formatToCurrency("fr-FR", "EUR", subscriptionPrice, undefined, false)}
            </span>
            <span className="ml-1 font-normal sm:text-xl text-md">{i18n.t('catalog.label.subscriptionUnit')}</span>
          </p>
        </div>

        {/* Upfront */}
        <p className="flex gap-1 text-sm sm:text-base">
          <span>+</span>
          {hasUpfrontPriceDrop && <span className="text-black/50 line-through">{formatToCurrency("fr-FR", "EUR", originalUpfrontPrice ?? 0, undefined, true)}</span>}
          <span className={hasUpfrontPriceDrop ? "font-semibold text-cornflower-blue tracking-tight" : 'tracking-tight'}>
            {formatToCurrency("fr-FR", "EUR", upfrontPrice ?? 0, undefined, true)}
          </span>
          <span>{i18n.t('catalog.label.upfrontPrefix')}</span>
        </p>
      </div>
    </CatalogProductCard>
  );
};
