import { CatalogProductCardAvailable } from "shared/catalog/AvailableCatalog/CatalogProductCardAvailable";
import { BestSellersEntries } from "shared/catalog/BestSellersCatalog/best-sellers-catalog.helper";

type BestSellerItemProps = {
  item: NonNullable<BestSellersEntries>[number];
};

export const BestSellerItem = ({
  item: {
    isNew,
    isOutOfStock,
    minimumSubscriptionPrice,
    minimumUpfrontPrice,
    modelName,
    brand,
    originalUpfrontPrice,
    originalSubscriptionPrice,
    picture,
    availableColors,
    slug,
    tagSlugs,
  },
}: BestSellerItemProps) => (
  <CatalogProductCardAvailable
    key={slug}
    brand={brand}
    slug={slug}
    modelName={modelName}
    isNew={isNew}
    isOutOfStock={isOutOfStock}
    image={picture}
    colorCodes={availableColors}
    upfrontPrice={minimumUpfrontPrice}
    subscriptionPrice={minimumSubscriptionPrice}
    originalUpfrontPrice={originalUpfrontPrice}
    originalSubscriptionPrice={originalSubscriptionPrice}
    tagSlugs={tagSlugs}
  />  
);
